import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContactMailIcon from '@material-ui/icons/MailOutlined';
import ContactPhoneIcon from '@material-ui/icons/PhoneOutlined';
import React from 'react';
import anime from './images/anime.jpg';
import eagle1 from './images/eagle 1.jpg';
import eagle2 from './images/eagle 2.jpg';
import gator1 from './images/gator 1.jpg';
import gator2 from './images/gator 2.jpg';
import goku from './images/goku.jpg';
import redmill from './images/redmill elementary.jpg';
import surfboard from './images/surfboard fence.jpg';
import thumbnail from './images/thumbnail.jpg';
import wave from './images/wave canvas.jpg';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  imgRoot: {
    display: "flex",
    justifyContent: 'center',
    flexFlow: 'column',
    marginTop: theme.spacing(2),
  },
  imgDiv: {
    overflow: 'hidden',
  },
  img: {
    maxWidth: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  titleSm: {
    lineHeight: 0,
  },
  dialogContent: {
    overflow: 'scroll',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
  dialogImg: {
    display: 'block',
    margin: '0 auto',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Img = ({ src, alt, classes }) => {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <div className={classes.imgDiv}>
        <img src={src} alt={alt} className={classes.img} onClick={toggleOpen} />
      </div>
      <Dialog fullScreen open={open} onClose={toggleOpen} TransitionComponent={Transition}>
        <div className={classes.dialogContent}>
          <img src={src} alt={alt} onClick={toggleOpen} className={classes.dialogImg} />
        </div>
      </Dialog>
    </React.Fragment>
  );
}

function Demo() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" className={classes.title} >
            Dreams 2 Reality
          </Typography>
          <a href="mailto:mattjd2r@yahoo.com?subject=Inquiry">
            <IconButton edge="start" aria-label="menu">
              <ContactMailIcon />
            </IconButton>
          </a>
          <a href="tel:17574502884">
            <IconButton edge="start" aria-label="menu">
              <ContactPhoneIcon />
            </IconButton>
          </a>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Hidden smDown>
          <Typography variant="h3" className={classes.title} >
            Custom murals and artwork
          </Typography>
          <Typography variant="h6">
            by Matthew Richard Jackson<br />
            Phone: 17574502884<br />
            Email: mattjd2r@yahoo.com
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography variant="h5" className={classes.title} >
            Custom murals and artwork
          </Typography>
          <Typography variant="body1">
            by Matthew Jackson<br />
            Phone: 17574502884<br />
            Email: mattjd2r@yahoo.com
        </Typography>
        </Hidden>
        <div className={classes.imgRoot}>
          <Img src={anime} alt="change" classes={classes}></Img>
          <Img src={eagle1} alt="change" classes={classes}></Img>
          <Img src={eagle2} alt="change" classes={classes}></Img>
          <Img src={gator1} alt="change" classes={classes}></Img>
          <Img src={gator2} alt="change" classes={classes}></Img>
          <Img src={goku} alt="change" classes={classes}></Img>
          <Img src={redmill} alt="change" classes={classes}></Img>
          <Img src={surfboard} alt="change" classes={classes}></Img>
          <Img src={thumbnail} alt="change" classes={classes}></Img>
          <Img src={wave} alt="change" classes={classes}></Img>
        </div>
      </div>
      <div style={{
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: 10,
      }}>
        Copyright &#169; D2R Inc. 2020
      </div>
    </React.Fragment>
  );
}

const darkTheme = createMuiTheme({
  palette: {
    // Switching the dark mode on is a single property value change.
    type: 'dark',
  },
});

export default function DarkTheme() {
  return (
    <div style={{ width: '100%' }}>
      <ThemeProvider theme={darkTheme}>
        <Demo />
      </ThemeProvider>
    </div>
  );
}